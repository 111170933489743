<template>
  <div>
    <CustomBottomSheet
      refName="UserDebtInfo"
      size="lg"
      :headerText="$t('Debts.data')"
      :headerIcon="debts"
    >
      <div class="row">
        <template>
          <div v-if="debtsData" class="table-container">
            <table class="my-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{ $t("general.value") }}</th>
                  <th>{{ $t("general.paid") }}</th>
                  <th>{{ $t("general.remainding") }}</th>
                  <th>{{ $t("Debts.schedulingStatusType") }}</th>
                  <th><i class="fas fa-sliders-h"></i></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(debt, index) in debtsData" :key="index">
                  <td>{{ ++index }}</td>
                  <td>
                    {{ isDataExist(debt.debtMoneyWithCurrency) }}
                  </td>
                  <td>
                    {{ isDataExist(debt.paidMoneyWithCurrency) }}
                  </td>
                  <td>
                    {{ isDataExist(debt.remainderMoneyWithCurrency) }}
                  </td>
                  <td>
                    {{ isDataExist(debt.schedulingStatusTypeNameCurrent) }}
                  </td>

                  <td class="fmenu-item-container">
                    <!-- <FloatingMenu> -->
                    <li
                      v-if="
                        checkPrivilege(hasInstallmentScheduleTimeAdd()) &&
                        debt.schedulingStatusTypeToken ==
                          SCHEDULING_STATUS_TOKENS.NotHaveSchedule
                      "
                    >
                      <button
                        @click="
                          setDebtTokenOfUser(debt);
                          openBottomSheet('InstallmentScheduleTimeAdd');
                        "
                        :title="$t('Expenses.pay')"
                      >
                        <img src="@/assets/images/installments.svg" />
                      </button>
                    </li>

                    <li
                      v-if="
                        checkPrivilege(hasInstallmentScheduleTime()) &&
                        debt.schedulingStatusTypeToken ==
                          SCHEDULING_STATUS_TOKENS.IsHaveSchedule
                      "
                    >
                      <router-link
                        :to="{
                          name: 'InstallmentScheduleTimes',
                          params: {
                            userToken: ' ',
                            debtToken: debt.debtToken,
                          },
                        }"
                        target="_blank"
                        :title="$t('Expenses.pay')"
                      >
                        <img src="@/assets/images/installments.svg" />
                      </router-link>
                    </li>
                    <!-- </FloatingMenu> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </div>
      <div class="form-actions">
        <div
          @click.prevent="closeBottomSheet('UserDebtInfo')"
          class="icon-cancel"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </CustomBottomSheet>
  </div>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
// import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";
import {
  hasInstallmentScheduleTimeAdd,
  hasInstallmentScheduleTime,
} from "./../../../../utils/privilegeHelper";
import { checkPrivilege, isDataExist } from "./../../../../utils/functions";
// import apiEducationalGroup from "./../../../../api/educational/educationalGroups";
import generalMixin from "./../../../../utils/generalMixin";
import debts from "@/assets/images/debts.svg";
import { SCHEDULING_STATUS_TOKENS } from "./../../../../utils/constantLists";

export default {
  mixins: [generalMixin],
  components: {
    CustomBottomSheet,
    // FloatingMenu,
  },
  props: {
    debtsData: { type: Array, default: () => [] },
  },
  data() {
    return {
      debts,
      SCHEDULING_STATUS_TOKENS,
    };
  },
  methods: {
    setDebtTokenOfUser(debt) {
      this.$emit("setDebtTokenOfUser", debt.debtToken);
    },
    checkPrivilege,
    isDataExist,
    hasInstallmentScheduleTimeAdd,
    hasInstallmentScheduleTime,
  },
  created() {},
};
</script>
