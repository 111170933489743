import defaultImg from "@/assets/images/EducationalScheduleUsers.svg";

export default class EducationalScheduleUser {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.userToken = "";
    this.userNameCurrent = "";
    this.fullCode = "";
    this.userPhone = "";
    this.userPhoneCC = "";
    this.userPhoneCCName = "";
    this.userPhoneWithCC = "";
    this.userImagePath = "";
    this.countWarningText = "";
    this.countTureWarning = 0;
    this.warningStatus1 = false;
    this.warningStatus2 = false;
    this.warningStatus3 = false;
    this.warningStatus4 = false;
    this.warningStatus5 = false;
    this.studentStatusColorHex = "";
    this.studentStatusText = "";
    this.educationalScheduleHalperTokens = {
      educationalGroupStudentToken: "",
      educationalJoiningApplicationToken: "",
    };
    this.subscriptionData = {
      subscriptionTypeToken: "",
      subscriptionTypeNameCurrent: "",
      subscriptionRequestSessionsNumber: 0,
      subscriptionRemaningSessionsNumberCount: 0,
      subscriptionFinishedSessionsNumberCount: 0,
      subscriptionFromDateTime: "",
      subscriptionFromDateTimeCustomized: "",
      subscriptionFromDate: "",
      subscriptionFromTime: "",
      subscriptionToDateTime: "",
      subscriptionToDateTimeCustomized: "",
      subscriptionToDate: "",
      subscriptionToTime: "",
      canAttendaceStatus: false,
      errorCannotAttendanceReasonCurrent: "",
    };
    this.currentEducationalScheduleData = {
      educationalGroupAttendanceToken: "",
      attendanceTypeToken: "",
      attendanceTypeNameCurrent: "",
      attendFromDateTime: "",
      attendFromCustomized: "",
      attendFromDate: "",
      attendFromTime: "",
      attendToDateTime: "",
      attendToCustomized: "",
      attendToDate: "",
      attendToTime: "",
      durationCurrent: "",
      attendanceNotes: "",
      countWarningText: "",
      countTureWarning: 0,
      warningStatus1: false,
      warningStatus2: false,
      warningStatus3: false,
      warningStatus4: false,
      warningStatus5: false,
      scheduleTimeInfoData: {},
      placeInfoData: {},
    };
    this.lastEducationalScheduleData = [];
    this.subscriptionDebtsNotFinishedData = [];
  }
  fillData(data) {
    if (data) {
      this.userToken = data.userToken ?? "";
      this.userNameCurrent = data.userNameCurrent ?? "";
      this.fullCode = data.fullCode ?? "";
      this.userPhone = data.userPhone ?? "";
      this.userPhoneCC = data.userPhoneCC ?? "";
      this.userPhoneCCName = data.userPhoneCCName ?? "";
      this.userPhoneWithCC = data.userPhoneWithCC ?? "";
      this.userImagePath = data.userImagePath ?? "";
      this.countWarningText = data.countWarningText ?? "";
      this.countTureWarning = data.countTureWarning ?? 0;
      this.warningStatus1 = data.warningStatus1 ?? false;
      this.warningStatus2 = data.warningStatus2 ?? false;
      this.warningStatus3 = data.warningStatus3 ?? false;
      this.warningStatus4 = data.warningStatus4 ?? false;
      this.warningStatus5 = data.warningStatus5 ?? false;
      this.studentStatusColorHex = data.studentStatusColorHex ?? "";
      this.studentStatusText = data.studentStatusText ?? "";
      this.educationalScheduleHalperTokens =
        data.educationalScheduleHalperTokens ?? {};
      this.subscriptionData = data.subscriptionData ?? {};
      this.currentEducationalScheduleData =
        data.currentEducationalScheduleData ?? {};
      this.lastEducationalScheduleData = data.lastEducationalScheduleData ?? [];
      this.subscriptionDebtsNotFinishedData =
        data.subscriptionDebtsNotFinishedData ?? [];
    } else {
      this.setInitialValue();
    }
  }
}
