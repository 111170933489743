var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CustomBottomSheet',{attrs:{"refName":"UserDebtInfo","size":"lg","headerText":_vm.$t('Debts.data'),"headerIcon":_vm.debts}},[_c('div',{staticClass:"row"},[[(_vm.debtsData)?_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("general.value")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.paid")))]),_c('th',[_vm._v(_vm._s(_vm.$t("general.remainding")))]),_c('th',[_vm._v(_vm._s(_vm.$t("Debts.schedulingStatusType")))]),_c('th',[_c('i',{staticClass:"fas fa-sliders-h"})])])]),_c('tbody',_vm._l((_vm.debtsData),function(debt,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(++index))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(debt.debtMoneyWithCurrency))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(debt.paidMoneyWithCurrency))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(debt.remainderMoneyWithCurrency))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist(debt.schedulingStatusTypeNameCurrent))+" ")]),_c('td',{staticClass:"fmenu-item-container"},[(
                      _vm.checkPrivilege(_vm.hasInstallmentScheduleTimeAdd()) &&
                      debt.schedulingStatusTypeToken ==
                        _vm.SCHEDULING_STATUS_TOKENS.NotHaveSchedule
                    )?_c('li',[_c('button',{attrs:{"title":_vm.$t('Expenses.pay')},on:{"click":function($event){_vm.setDebtTokenOfUser(debt);
                        _vm.openBottomSheet('InstallmentScheduleTimeAdd');}}},[_c('img',{attrs:{"src":require("@/assets/images/installments.svg")}})])]):_vm._e(),(
                      _vm.checkPrivilege(_vm.hasInstallmentScheduleTime()) &&
                      debt.schedulingStatusTypeToken ==
                        _vm.SCHEDULING_STATUS_TOKENS.IsHaveSchedule
                    )?_c('li',[_c('router-link',{attrs:{"to":{
                        name: 'InstallmentScheduleTimes',
                        params: {
                          userToken: ' ',
                          debtToken: debt.debtToken,
                        },
                      },"target":"_blank","title":_vm.$t('Expenses.pay')}},[_c('img',{attrs:{"src":require("@/assets/images/installments.svg")}})])],1):_vm._e()])])}),0)])]):_vm._e()]],2),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();return _vm.closeBottomSheet('UserDebtInfo')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }