<template>
  <div class="">
    <DashboardNavbar />
    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <template v-if="hasData">
        <EducationalScheduleUserTable
          :usersData="usersData"
          :educationalScheduleTime="
            educationalScheduleUsers.educationalScheduleTime
          "
          :defaultImg="
            educationalScheduleUsers.educationalScheduleUser.defaultImg
          "
          v-on:setEducationalScheduleUserData="
            educationalScheduleUsers.educationalScheduleUser.fillData($event)
          "
          v-on:setEducationalGroupUpdateWarningData="
            educationalGroupUpdateWarning.fillData($event)
          "
          v-on:setJoiningApplicationSubscriptionData="
            joiningApplicationSubscriptions.joiningApplicationSubscription.fillData(
              $event
            )
          "
          v-on:setSelectedSubscriptionDebtsNotFinishedData="
            selectedSubscriptionDebtsNotFinishedData = $event.list;
            userTokenOfDebt = $event.userToken;
          "
          v-on:setEducationalGroupAttendanceData="
            educationalGroupAttendance.fillData($event)
          "
          v-on:refresh="
            getEducationalScheduleTimeDetails();
            getAllEducationalScheduleUsers();
          "
          class=""
        />
        <EducationalGroupUpdateWarning
          :educationalGroupUpdateWarning="educationalGroupUpdateWarning"
          v-on:refresh="
            getEducationalScheduleTimeDetails();
            getAllEducationalScheduleUsers();
          "
          class=""
        />
        <UserDebtInfo
          :debtsData="selectedSubscriptionDebtsNotFinishedData"
          v-on:setDebtTokenOfUser="debtTokenOfUser = $event"
        />

        <InstallmentScheduleTimeAdd
          :installmentScheduleTime="
            installmentScheduleTimes.installmentScheduleTime
          "
          :userTokenOfDebt="userTokenOfDebt"
          :debtTokenOfUser="debtTokenOfUser"
          v-on:refresh="
            getEducationalScheduleTimeDetails();
            getAllEducationalScheduleUsers();
          "
        />

        <JoiningApplicationSubscriptionAdd
          :joiningApplicationSubscription="
            joiningApplicationSubscriptions.joiningApplicationSubscription
          "
          v-on:refresh="
            getEducationalScheduleTimeDetails();
            getAllEducationalScheduleUsers();
          "
          class=""
        />
        <EducationalGroupAttendanceDelete
          :educationalGroupAttendance="educationalGroupAttendance"
          v-on:refresh="
            getEducationalScheduleTimeDetails();
            getAllEducationalScheduleUsers();
          "
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
    </div>
  </div>
</template>

<script>
import { STATUS } from "./../../../utils/constants";
import DashboardNavbar from "./../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../../components/general/ExceptionWithImg.vue";
import EducationalScheduleUserTable from "./components/EducationalScheduleUserTable.vue";
import UserDebtInfo from "./components/UserDebtInfo.vue";
import EducationalScheduleUsers from "./../../../models/educational/educationalScheduleUsers/EducationalScheduleUsers";
import apiEducationalScheduleTime from "./../../../api/educational/educationalScheduleTimes";
import generalMixin from "./../../../utils/generalMixin";
import EducationalGroupUpdateWarningClass from "./../../../models/educational/educationalGroupStudents/EducationalGroupUpdateWarning";
import EducationalGroupUpdateWarning from "./../../../views/educational/educationalGroupStudents/components/EducationalGroupUpdateWarning.vue";
import JoiningApplicationSubscriptions from "./../../../models/educational/joiningApplicationSubscriptions/JoiningApplicationSubscriptions";
import JoiningApplicationSubscriptionAdd from "./../../../views/educational/joiningApplicationSubscriptions/components/JoiningApplicationSubscriptionAdd.vue";
import studentsImg from "@/assets/images/students.svg";
import InstallmentScheduleTimes from "./../../../models/joiningApplications/installmentScheduleTimes/InstallmentScheduleTimes";
import InstallmentScheduleTimeAdd from "./../../../views/joiningApplications/installmentScheduleTimes/components/InstallmentScheduleTimeAdd.vue";
import EducationalGroupAttendanceModelUpdate from "./../../../models/educational/educationalGroupAttendances/EducationalGroupAttendanceModelUpdate";
import EducationalGroupAttendanceDelete from "./../educationalGroupAttendances/components/EducationalGroupAttendanceDelete.vue";

export default {
  mixins: [generalMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    ExceptionWithImg,
    EducationalScheduleUserTable,
    UserDebtInfo,
    EducationalGroupUpdateWarning,
    JoiningApplicationSubscriptionAdd,
    InstallmentScheduleTimeAdd,
    EducationalGroupAttendanceDelete,
  },
  props: {
    educationalScheduleTimeToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      installmentScheduleTimes: new InstallmentScheduleTimes(),
      educationalScheduleUsers: new EducationalScheduleUsers(),
      educationalGroupUpdateWarning: new EducationalGroupUpdateWarningClass(),
      educationalGroupAttendance: new EducationalGroupAttendanceModelUpdate(),
      studentsImg,
      joiningApplicationSubscriptions: new JoiningApplicationSubscriptions(),
      selectedSubscriptionDebtsNotFinishedData: [],
      userTokenOfDebt: "",
      debtTokenOfUser: "",
    };
  },
  computed: {
    hasData() {
      return (
        this.educationalScheduleUsers.educationalScheduleUsersData.length != 0
      );
    },
    usersData() {
      let hasData = false;
      let debtMoney = 0;
      let paidMoney = 0;
      let remainderMoney = 0;
      let userDebtInfo = {};
      let usersDataList = [];

      this.educationalScheduleUsers.educationalScheduleUsersData.forEach(
        (element) => {
          hasData = false;
          debtMoney = 0;
          paidMoney = 0;
          remainderMoney = 0;
          hasData = element.subscriptionDebtsNotFinishedData.length;

          if (hasData) {
            element.subscriptionDebtsNotFinishedData.forEach((debt) => {
              debtMoney += debt.debtMoney;
              paidMoney += debt.paidMoney;
              remainderMoney += debt.remainderMoney;
            });
          }

          userDebtInfo = {
            hasData: hasData,
            debtMoney: debtMoney,
            paidMoney: paidMoney,
            remainderMoney: remainderMoney,
          };

          usersDataList.push({
            ...element,
            userDebtInfo,
          });
        }
      );

      return usersDataList;
    },
  },
  methods: {
    async getEducationalScheduleTimeDetails() {
      this.isLoading = true;

      try {
        this.educationalScheduleUsers.educationalScheduleTime.setInitialValue();
        const response = await apiEducationalScheduleTime.getDetails({
          token: this.educationalScheduleTimeToken,
        });
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.educationalScheduleUsers.educationalScheduleTime.fillData(
          response.data.educationalScheduleTime
        );
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
    async getAllEducationalScheduleUsers() {
      this.isLoading = true;
      try {
        this.educationalScheduleUsers.educationalScheduleUsersData = [];
        const response = await apiEducationalScheduleTime.getEnquiry({
          educationalScheduleTimeToken: this.educationalScheduleTimeToken,
        });
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.educationalScheduleUsers.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getEducationalScheduleTimeDetails();
    this.getAllEducationalScheduleUsers();
  },
};
</script>
