import EducationalScheduleUser from "./EducationalScheduleUser";
import EducationalScheduleTime from "./../educationalScheduleTimes/EducationalScheduleTime";

export default class EducationalScheduleUsers {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.educationalScheduleUsersData = [];
    this.educationalScheduleUser = new EducationalScheduleUser();
    this.educationalScheduleTime = new EducationalScheduleTime();
  }
  fillData(data) {
    if (data) {
      this.educationalScheduleUsersData =
        data.educationalScheduleUsersData || [];
    } else {
      this.setInitialValue();
    }
  }
}
